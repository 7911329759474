const data = [
  {
    name: "Início",
    path: null,
    children: [
      { name: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/home", path: null },
    ],
  },
  {
    name: "Gestão",
    path: "Manager",
    children: [
      { name: "Administradores", icon: "mdi-account-supervisor-circle-outline", route: "/admins", path: "Admin" },
      { name: "Análise de Ofício", icon: "mdi-text-box-search-outline", route: "/analise-oficio", path: "LetterAnalysis" },
      { name: "Casos", icon: "mdi-account-file-outline", route: "/casos", path: "Client" },
      { name: "Colaboradores", icon: "mdi-account-multiple-outline", route: "/colaboradores", path: "Employee" },
      { name: "Comarcas", icon: "mdi-map-marker-distance", route: "/comarcas", path: "District" },
      // { name: "Credenciamento", icon: "mdi-account-eye-outline", route: "/credenciamentos", path: "Accreditation" },
      { name: "Credores", icon: "mdi-account-group-outline", route: "/credores", path: "User" },
      { name: "Documentos", icon: "mdi-file-outline", route: "/documentos", path: "Document" },
      { name: "Hab/Div", icon: "mdi-sync-alert", route: "/divergencias", path: "Event" },
      { name: "Incidentes Judiciais", icon: "mdi-account-multiple-outline", route: "/incidentes", path: "JudicialIncidents" },
      { name: "Lista de Credores", icon: "mdi-account-details-outline", route: "/lista-credores", path: "Creditors" },
      { name: "QGC", icon: "mdi-card-account-details-outline", route: "/qgc", path: "Qgc" },
      { name: "Textos Automáticos (RVC)", icon: "mdi-text-box-outline", route: "/textos-automaticos", path: "DynamicText" },
      //{ name: "Votação", icon: "mdi-vote-outline", route: "/votacao-lista", path: "VotingList" },     
      
    ],
  },
  {
    name: "Site",
    path: "Site",
    children: [
      { name: "Assembléia de Credores", icon: "mdi-video-outline", route: "/agcs", path: "GeneralMeeting" },
      { name: "Banner de Aviso", icon: "mdi-invoice-text-clock-outline", route: "/banner-aviso", path: "HomeBanner" },
      { name: "Dúvidas", icon: "mdi-account-question-outline", route: "/faqs", path: "Faq" },
      { name: "Modelo de Documentos", icon: "mdi-file-sign", route: "/modelo-documentos", path: "TemplateDoc" },
      { name: "Processos Mais Consultados", icon: "mdi-text-box-search-outline", route: "/processos-mais-consultados", path: "MostConsulted" },
      { name: "Textos Dinâmicos", icon: "mdi-file-chart-outline", route: "/textos-dinamicos", path: "DynamicInfo" },    
      // { name: "Atos Processuais", icon: "mdi-file-document-edit-outline", route: "/vehicles" },
      // { name: "Habilitações e Divergências", icon: "mdi-text-box-check-outline", route: "/vehicles" },
    ],
  },
  {
    name: "Blog",
    path: "Site",
    children: [
      { name: "Categorias", icon: "mdi-shape-outline", route: "/blog/categorias", path: "BlogCategory" },
      { name: "Postagens", icon: "mdi-post-outline", route: "/blog/posts", path: "Blog" },
    ],
  },
];

export default [...data];
