<template>
    <v-list-item link :to="item.route" color="primary" @click.native="emitEvent(item)">
        <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
import { eventHub } from "@/main";

export default {
    name: "MenuItem",

    props: ["item"],

    methods: {
        emitEvent(item) {
            if (item.event) {
                eventHub.$emit(item.event)
                return;
            }

            eventHub.$emit('close-menu');
        },
    },
};
</script>
  
<style lang="scss" scoped></style>