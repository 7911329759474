import { render, staticRenderFns } from "./Private.vue?vue&type=template&id=61186370&scoped=true&"
import script from "./Private.vue?vue&type=script&lang=js&"
export * from "./Private.vue?vue&type=script&lang=js&"
import style0 from "./Private.vue?vue&type=style&index=0&id=61186370&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61186370",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAppBar,VAppBarNavIcon,VAvatar,VCard,VImg,VList,VMenu,VNavigationDrawer,VSpacer,VSubheader})
